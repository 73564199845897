import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import HeaderAppyShop, {getComponentStaticProps, HeaderProps} from '../components/header/headerAppyShop'
import styles from './404.module.scss'
import Link from "next/link";
import FooterAppyShop from '../components/footer/footerAppyShop';
import { useTranslation } from 'next-i18next';
import { config } from '../config-merchant';
import Meta from "../components/meta/meta";
import {appyShopConfig} from '../config'

export default function FourOhFour(props: { headerProps: HeaderProps }) {

    const { t } = useTranslation('common');
    const { i18n } = useTranslation('common')

    return (
        <>
            <Meta
                title={""}
                description={config.shopSlogan[i18n.language]}
                image={config.merchantDomain + config.defaultMetaImage}
                metaRobots={false}
            />
            <HeaderAppyShop headerProps={props.headerProps}></HeaderAppyShop>
            <div className={styles.main}>
                <h1> 404 </h1>
                <p> {t('404_NOT_FOUND')} </p>
                <br />
                <p> {t('404_NOT_FOUND_BIS')}</p>
                <br />
                <Link href="/home"><div className={styles['fourohfour']}><p>{t('404_NOT_FOUND_BUTTON')}</p>  </div></Link>
            </div>

            <FooterAppyShop></FooterAppyShop>
        </>)
}

export const getStaticProps = async ({ locale }) => {
    return {
        props: {
            ...await serverSideTranslations(locale, ['common']),
            headerProps: await getComponentStaticProps(locale)
        },
        revalidate: appyShopConfig.revalidateData
    }
}
